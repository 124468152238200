import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RenderSections from "../components/sanity-v2/RenderSections"

const DefaultTemplate = ({ data, slug }) => {
  const sanityContent = data.sanityPage
  return (
    <Layout>
      <SEO
        title="CoderSchool"
        singleLang={sanityContent.singleLang}
        baseUrl={sanityContent.slug.current}
        openGraphImage={sanityContent.openGraphImage}
        description={sanityContent.description}
      />
      <RenderSections sections={sanityContent.content} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      description
      openGraphImage {
        ...SanityImageFields
      }
      singleLang
      content {
        ... on SanityDemoProject {
          _type
          _key
          heading {
            vi
            en
          }
          subtitle {
            _key
            _type
            _rawEn
            _rawVi
          }
          textColor
          backgroundColor
          youTubeId
          name {
            vi
            en
          }
          profilePic {
            ...SanityImageFields
          }
          pic1 {
            ...SanityImageFields
          }
          pic2 {
            ...SanityImageFields
          }
          pic3 {
            ...SanityImageFields
          }
          description {
            _key
            _type
            _rawEn
            _rawVi
          }
        }
        ... on SanityIframe {
          _key
          _type
          link
        }
        ...superSectionFields
        ...heroFields
        ...heroV2Fields
        ...accordionFields
        ...companiesListFields
        ... on SanityTextWithFourIcons {
          _key
          _type
          description {
            en
            vi
          }
          heading {
            vi
            en
          }
          subHeading {
            vi
            en
          }
          technicalIcons {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          fullBleedImages
        }

        ... on SanityPhotoBlock {
          _type
          heading {
            en
            vi
          }
          images {
            ...ImageWithPreview
          }
        }
        ... on SanityCurriculumDetail {
          _key
          _type
          heading {
            vi
            en
          }
          description {
            en
            vi
          }
        }
        ... on SanityGraduateProjectsList {
          _key
          _type
          graduateProjects {
            ...projectFields
          }

          heading {
            en
            vi
          }
        }
        ... on SanityCtas {
          _key
          _type
          ctas {
            openNewTab
            link
            color
            size
            title {
              en
              vi
            }
          }
        }
        ...whyChooseFields
        ... on SanityTextBlock {
          _key
          _type
          heading {
            en
            vi
          }
          description {
            en
            vi
          }
        }
        ... on SanityCoursesList {
          _type
          _key
          description {
            en
            vi
          }
          heading {
            en
            vi
          }
        }
        ... on SanityIconBenefitsList {
          _key
          _type
          heading {
            en
            vi
          }
          benefits {
            ...benefitFields
          }
        }
        ... on SanityEmployeesListFocused {
          _key
          _type
        }
        ... on SanityPaymentOptionsList {
          _key
          _type
          description {
            en
            vi
          }
          heading {
            en
            vi
          }

          paymentOptions {
            ...paymentOptionFields
          }
        }
        ... on SanityFrequentlyAskedQuestionsList {
          _key
          _type
          heading {
            en
            vi
          }
          questions {
            content {
              en
              vi
            }
            _key
            title {
              en
              vi
            }
          }
        }
        ... on SanityEmbed {
          _key
          _type
          heading {
            en
            vi
          }
          embedCode
        }
        ... on SanityHighlightFeaturesList {
          _key
          _type

          highlightFeatures {
            _key
            ...highlightFeatureFields
          }
        }
        ... on SanityLearningOptionsList {
          _key
          _type
          learningOptions {
            ...learningOptionFields
          }

          heading {
            en
            vi
          }

          ctas {
            link
            openNewTab
            color
            size
            title {
              en
              vi
            }
          }
        }
        ... on SanityPipedriveWebform {
          _type
          _key
          heading {
            en
            vi
          }
          testimony {
            id
            title {
              _key
              _type
              en
              vi
            }
            subtitle {
              _key
              _type
              en
              vi
            }
            body {
              _key
              _type
              _rawEn
              _rawVi
            }
            signature {
              _key
              _type
              en
              vi
            }
            image {
              ...Image
            }
          }
          pipedriveLink
        }
        ... on SanityJotform {
          _key
          _type
          heading {
            en
            vi
          }
          jotformLink
          testimony {
            id
            title {
              _key
              _type
              en
              vi
            }
            subtitle {
              _key
              _type
              en
              vi
            }
            body {
              _key
              _type
              _rawEn
              _rawVi
            }
            signature {
              _key
              _type
              en
              vi
            }
            image {
              ...Image
            }
          }
        }
        ... on SanityJobOpeningsList {
          _key
          _type
          heading {
            en
            vi
          }
          jobOpenings {
            id
            jdLink
            title
          }
        }
        ... on SanityStudentSuccessStory {
          _key
          _type
          title {
            en
            vi
          }
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          content {
            _rawEn
            _rawVi
          }
        }
      }
    }
  }
`

export default DefaultTemplate
